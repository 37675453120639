<template>
  <div class="padding-left-layout padding-right-layout padding-top-layout">
    <page-title :title="titleText" />
    <div v-if="!isShowLoading">
      <div v-if="!showListReductionTarge">
        <div class="cut-target-setting-header">
          <div class="cut-target-setting-header--icon">
            <img class="warning-icon" width="28" height="28" src="@/assets/icons/notreductiontargeticon.svg" />
          </div>
          <div class="cut-target-setting-header--note">
            <p>{{ $t('reduction.description_not_emission') }}</p>
            <p>{{ $t('reduction.description_setting_emission') }}</p>
          </div>
          <div class="cut-target-setting-header--button">
            <common-button
              class="submit-register-btn"
              :label="labelBtnRegister"
              type="colored"
              @action="handleSetting"
              v-ripple="false"
            />
          </div>
        </div>
        <div class="cut-target-setting-bottom"></div>
      </div>
      <div v-else>
        <div>
          <div class="target-setting-show">
            <div class="target-setting-show-header" :class="!showMoreBody && 'boder-bottom'">
              <div class="target-setting-show-header--flag-icon">
                <img class="warning-icon" width="28" height="28" src="@/assets/icons/flag.svg" />
                <span>{{ $t('reduction.title_header_content') }}</span>
              </div>
              <div class="target-setting-show-header--actions">
                <div class="target-setting-show-header--actions--reduction">
                  <v-select
                    solo
                    flat
                    :items="listReductionTargetSelect"
                    v-model="defaultValueSelect"
                    item-text="text"
                    item-value="value"
                    :label="placeholderPulldownReduction"
                    class="select-type list-menu--select select-item select-reduction"
                    :menu-props="{ contentClass: 'select-menu product-select' }"
                    @change="handleChangeReductionTargetSelect"
                  />
                  <common-button
                    class="btn-reduction"
                    :label="labelButtonAddTarget"
                    type="colored"
                    @action="handleSetting"
                    v-ripple="false"
                  />
                </div>
                <div class="target-setting-add-new" @click="handleToggleMenu">
                  <div class="target-setting-add-new--select">
                    <img
                      class="target-setting-add-new--select--icon normal-icon pulldown-icon"
                      :class="{ open: showMoreBody }"
                      src="@/assets/icons/pulldown.svg"
                      alt=""
                    />
                    <img
                      class="target-setting-add-new--select--icon active-icon pulldown-icon"
                      :class="{ open: showMoreBody }"
                      src="@/assets/icons/pulldown-active.svg"
                      alt=""
                    />
                    <div class="target-setting-add-new--select--label">
                      {{ showMoreBody ? $t('reduction.button_show_details') : $t('reduction.button_close_details') }}
                    </div>
                  </div>
                  <div class="target-setting-pulldown" v-if="false">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="target-setting-body" :class="showMoreBody && 'closed-menu'">
              <div class="target-setting-body--left">
                <div class="target-setting-body--left--content">
                  <div class="target-setting-duration">
                    <div class="target-setting-body--left--box">
                      <span>{{ $t('reduction.label_reference_year') }}</span>
                      <p>
                        {{
                          $t('list_reduction.table_year_fiscal_standard_target', {
                            year: reductionTargetDetail.start_year,
                          })
                        }}
                      </p>
                    </div>
                    <div class="target-setting-body--left--box ml-6">
                      <span>{{ $t('reduction.label_achievement_year') }}</span>
                      <p>
                        {{
                          $t('list_reduction.table_year_fiscal_standard_target', {
                            year: reductionTargetDetail.end_year,
                          })
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="target-setting-body--left--content">
                  <div class="target-setting-body--left--box">
                    <span>{{ $t('reduction.label_organization_categories') }}</span>
                    <p>
                      {{ reductionTargetDetail.division ? convertArrToStringList(reductionTargetDetail.division) : '' }}
                    </p>
                  </div>
                </div>
                <div class="target-setting-body--left--content">
                  <div class="target-setting-body--left--box">
                    <span>{{ $t('reduction.label_companies') }}</span>
                    <p>
                      {{
                        reductionTargetDetail.company
                          ? convertArrToStringList(uniqueCompanyName(reductionTargetDetail.company))
                          : ''
                      }}
                    </p>
                  </div>
                </div>
                <div class="target-setting-body--left--content" v-for="(layer, index) in dataBranch" :key="index">
                  <div class="target-setting-body--left--box">
                    <span>{{ layer.layer_name }}</span>
                    <p>
                      {{
                        reductionTargetDetail[layer.new_key]
                          ? convertArrToStringList(uniqueCompanyName(emptyLable(reductionTargetDetail[layer.new_key])))
                          : ''
                      }}
                    </p>
                  </div>
                </div>
                <div class="target-setting-body--left--content">
                  <div class="target-setting-body--left--box">
                    <span>{{ $t('reduction.label_scope_Category') }}</span>
                    <p v-for="(item, index) in reductionTargetDetail.list_scrope_categories" :key="index">{{ item }}</p>
                  </div>
                </div>
                <div class="target-setting-body--left--content">
                  <div class="target-setting-body--left--box">
                    <span>{{ $t('reduction.label_note') }}</span>
                    <p>{{ reductionTargetDetail.note }}</p>
                  </div>
                </div>
              </div>
              <div class="target-setting-body--right">
                <div class="wrap__status">
                  <div class="wrap__status__item">
                    <img src="@/assets/icons/clock.svg" alt="" />
                    <div class="wrap__status__item__wrap">
                      <p>{{$t('common.last_update')}}</p>
                      <p>
                        {{
                          reductionTargetDetail?.latest_record?.updated_at_latest
                            ? dateTimeFormat(reductionTargetDetail?.latest_record?.updated_at_latest)
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="wrap__status__item">
                    <img src="@/assets/icons/userTable.svg" alt="" />
                    <div class="wrap__status__item__wrap">
                      <p>{{$t('common.updated_by')}}</p>
                      <p>
                        {{
                          reductionTargetDetail?.latest_record?.user_updated
                            ? reductionTargetDetail?.latest_record?.user_updated
                            : ''
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="setting-actions-reduce">
                  <div class="target-setting-add-new" @click="handleEdit">
                    <div class="target-setting-add-new--select">
                      <img
                        class="target-setting-add-new--select--icon normal-icon"
                        src="@/assets/icons/edit-reduction.svg"
                        alt=""
                      />
                      <img
                        class="target-setting-add-new--select--icon active-icon"
                        src="@/assets/icons/edit-reduction-active.svg"
                        alt=""
                      />
                      <div class="target-setting-add-new--select--label">{{ $t('reduction.button_edit') }}</div>
                    </div>
                    <div class="target-setting-pulldown" v-if="false">
                      <div></div>
                    </div>
                  </div>
                  <div class="target-setting-add-new mt-4" @click="showConfirmDelete = true">
                    <div class="target-setting-add-new--select">
                      <img
                        class="target-setting-add-new--select--icon normal-icon"
                        src="@/assets/icons/delete-reduction.svg"
                        alt=""
                      />
                      <img
                        class="target-setting-add-new--select--icon active-icon"
                        src="@/assets/icons/delete-reduction-active.svg"
                        alt=""
                      />
                      <div class="target-setting-add-new--select--label">{{ $t('reduction.button_delete') }}</div>
                    </div>
                    <div class="target-setting-pulldown" v-if="false">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pie-chart">
              <div class="pie-chart-box">
                <div class="pie-chart-box__title">
                  <span class="pie-chart-box__title--text">{{ $t('reduction.title_cumulative_discharge') }}</span>
                  <button class="button-active" v-if="labelEmission === false">
                    <img class="warning-icon" width="15" height="15" src="@/assets/icons/like.svg" />
                    {{ $t('reduction.label_going_well') }}
                  </button>
                  <button class="button-disable" v-if="labelEmission === true">
                    <img class="warning-icon" width="15" height="15" src="@/assets/icons/exclamationMarkIcon.svg" />
                    {{ $t('reduction.label_to_improve') }}
                  </button>
                </div>
                <div class="pie-chart-box-wrap">
                  <div class="pie-chart-container">
                    <div class="pie-chart-container-content">
                      <ProgressChart
                        :progress="Number(reductionTargetDetail?.emissions_value_accumulation?.percent_value)"
                      />
                    </div>
                  </div>
                  <div class="pie-chart-content left-shift">
                    <div class="pie-chart-content-top">
                      <p class="title-text">{{ $t('reduction.label_target') }}</p>
                      <p class="values-text values-top">
                        {{
                          formatNumberValue(reductionTargetDetail?.emissions_value_accumulation?.emissions_target_value)
                        }}
                        <span class="unit-text">t-CO2</span>
                      </p>
                    </div>
                    <div class="pie-chart-content-bottom">
                      <p class="title-text">{{ $t('reduction.label_achievements') }}</p>
                      <div class="pie-chart-achievement">
                        <p class="values-text">
                          {{
                            reductionTargetDetail?.emissions_value_accumulation?.emissions_actual_value
                              ? formatNumberValue(
                                  reductionTargetDetail?.emissions_value_accumulation?.emissions_actual_value,
                                )
                              : '-'
                          }}
                          <span class="unit-text">t-CO2</span>
                        </p>
                        <p class="values-text sub-value">
                          <img
                            class="warning-icon"
                            :class="{
                              'revert-icon': reductionTargetDetail.emissions_value_accumulation?.compare_value < 0,
                            }"
                            width="10"
                            height="10"
                            src="@/assets/icons/rectangle.svg"
                          />
                          {{ formatNumberValue(reductionTargetDetail?.emissions_value_accumulation?.compare_value) }}
                          <span class="unit-text">t-CO2</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pie-chart-box">
                <div class="pie-chart-box__title unset-justify-content">
                  <div class="title-chart">{{ $t('icp_target_integration.title_reduction_rate_current_period') }}</div> 
                  <!-- <div class="helper-icon">
                    <img
                      :src="getSettingIcon('helper-icon.svg')"
                      @mouseover="changeTooltipImage($event)"
                      @mouseleave="changeTooltipImage($event, 'mouseleave')"
                      alt=""
                    />
                    <div
                      v-if="showTooltip"
                      class="helper-icon tooltip"
                      v-html='$t("reduction.description_helper_message")'
                    ></div>
                  </div> -->
                  <div class="select-duration" :style="getStyleSelectDuration">
                    <v-select
                      solo
                      flat
                      :items="listSelectDurations"
                      v-model="selectDuration"
                      item-text="text"
                      item-value="value"
                      :label='$t("dashboard_main.label_year")'
                      class="select-type list-menu--select select-item select-reduction"
                      :menu-props="{ contentClass: 'select-menu product-select' }"
                      @change="handleChangeSelectDuration"
                    />
                  </div>
                </div>
                <div class="pie-chart-box-wrap">
                  <div class="pie-chart-container">
                    <div class="pie-chart-container-content">
                      <ProgressChart
                        :progress="Number(reductionTargetDetail?.emission_value_reduce_yearly?.percent_target_value)"
                      />
                    </div>
                  </div>
                  <div class="pie-chart-content left-shift">
                    <div class="pie-chart-content-top">
                      <p class="title-text">{{ getTitleTargetValueCurrentLabel }}</p>
                      <p class="values-text values-top">
                        {{ formatNumberValue(reductionTargetDetail?.emission_value_reduce_yearly?.target_value_cut) }}
                        <span class="unit-text">t-CO2</span>
                      </p>
                    </div>
                    <div class="pie-chart-content-bottom">
                      <p class="title-text">{{ getTitleAchieveValueCurrentLabel }}</p>
                      <div class="pie-chart-achievement">
                        <p class="values-text">
                          {{
                            reductionTargetDetail.emission_value_reduce_yearly?.emission_actual_standard
                              ? formatNumberValue(
                                  reductionTargetDetail?.emission_value_reduce_yearly?.emission_actual_standard,
                                )
                              : '-'
                          }}
                          <span class="unit-text">t-CO2</span>
                        </p>
                        <p class="values-text sub-value">
                          <img
                            class="warning-icon"
                            :class="{
                              'revert-icon':
                                reductionTargetDetail?.emission_value_reduce_yearly?.compare_target_value < 0,
                            }"
                            width="10"
                            height="10"
                            src="@/assets/icons/rectangle.svg"
                          />
                          {{
                            formatNumberValue(reductionTargetDetail?.emission_value_reduce_yearly?.compare_target_value)
                          }}
                          <span class="unit-text">t-CO2</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pie-chart-box">
                <!-- <img class="pie-chart-box-comingsoon" src="@/assets/images/products/comingsoon.svg" alt="coming soon"> -->
                <p class="pie-chart-box__title text-color-disable">{{ $t('reduction.title_cumulative_ICP') }}</p>

                <div class="pie-chart-box-wrap">
                  <div class="pie-chart-container">
                    <div class="pie-chart-container-content">
                      <ProgressChart :progress="Number(reductionTargetDetail?.round_icp?.percent)" />
                    </div>
                  </div>
                  <div class="pie-chart-content left-shift">
                    <div class="pie-chart-content-top">
                      <p class="title-text">{{ $t('reduction.label_target') }}</p>
                      <p class="values-text values-top">
                        {{ formatNumberValue(reductionTargetDetail?.round_icp?.target) }}
                        <span class="unit-text">JPY</span>
                      </p>
                    </div>
                    <div class="pie-chart-content-bottom">
                      <p class="title-text">{{ $t('reduction.label_achievements') }}</p>
                      <div class="pie-chart-achievement">
                        <p class="values-text">
                          {{
                            reductionTargetDetail.round_icp?.achieve_actual
                              ? formatNumberValue(reductionTargetDetail?.round_icp?.achieve_actual)
                              : '-'
                          }}
                          <span class="unit-text">JPY</span>
                        </p>
                        <p class="values-text sub-value">
                          <img
                            class="warning-icon"
                            :class="{ 'revert-icon': reductionTargetDetail?.round_icp?.compare < 0 }"
                            width="10"
                            height="10"
                            src="@/assets/icons/rectangle.svg"
                          />
                          {{ formatNumberValue(reductionTargetDetail?.round_icp?.compare) }}
                          <span class="unit-text">JPY</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="line-chart">
              <div class="assets-type-list">
                <div class="assets-type-item" v-for="(item, index) in legends" :key="index">
                  <span class="assets-point" :style="getStyleLegend(item)"></span>
                  <span class="assets-title">{{ item.text }}</span>
                </div>
              </div>
              <LineChart
                :dataYearDefault="allYearDefault"
                :dataAllLineChart="dataForLineChart"
                :paletteColor="legends"
                :key="reductionTargetDetail.id"
                :dataBottomICP="dataBottomICP"
                :startYear="startYear"
                :endYear="endYear"
                :startMonth="startMonth"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Hiển thị một spinner hoặc thông báo loading khi đang gọi API -->
    </div>

    <question-popup
      :closeText="cancelBtnText"
      :confirmText="confirmTextDelete"
      :dialog="showConfirmDelete"
      :message="confirmMessageDelete"
      :isSaveButtonDelete="isSaveButtonDelete"
      @submit="handleSubmitDelete"
      @close="showConfirmDelete = false"
    />
    <NotificationPopup
      :dialog="dialogPopupUpdateSuccess"
      :message="messageConfirmChange"
      @submit="handleCloseNotiPopup"
      classes="register"
    />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { ROUTES } from '@/router/constants';
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import CommonButton from '@/components/utils/button.vue';
import LineChart from '@/components/products/reductions/LineChart.vue';
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.chart.animation';
import { PALETTE_COLOR_REDUCTION, FAKE_VALUE_ZERO, ICP_TEXT } from '@/constants/products/define-data';
import QuestionPopup from '@/components/dialogs/question-popup.vue';
import {
  getListReductionTargetApi,
  getReductionTargetDetailApi,
  deleteReductionTargetApi,
} from '@/api/reduction-target';
import { scopeCategoriesData, oldmethod, newPcafMethod } from '@/constants/export-report';
import { formatDateTime } from '@/utils/datetimeFormat';
import { roundingNumber } from '@/utils/convertNumber';
import { handleGetScopeCategories } from '@/utils/getScopeCategories';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import ProgressChart from '@/components/products/reductions/progress.vue';
import SelectItem from '@/components/utils/selectItem.vue';
import { getDateCurrent } from '@/utils/getTextWithCondition';
import { getListBranchApi } from "@/api/facility";
import { getStartMonth } from "@/api/duration";
import { getWidthText } from "@/utils/calcTextWidth"
import { getDataTypeApi } from '@/api/pcaf'
import { handleUpdateRowNumpattern, setDataTypeForPcafMethod } from '@/utils/pcaf'

export default {
  name: 'ReductionTarget',
  props: {},
  components: { PageTitle, CommonButton, LineChart, QuestionPopup, NotificationPopup, ProgressChart, SelectItem },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t('reduction.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('reduction.label_breadcrum_title'),
          disabled: false,
          href: ROUTES.PRODUCT_REDUCTION,
        },
      ],
      dataAccumulation: [
        { brand: 'emissions_target_value', sales: 100 },
        { brand: 'emissions_achieve_value', sales: 0 },
      ],
      dataReduceYearly: [
        { brand: 'target_value_standard', sales: 100 },
        { brand: 'target_value_cut', sales: 0 },
      ],
      legends: [],
      dataAllLine: [],
      dataForLineChart: [],
      allYearDefault: [],
      listReductionTarget: [],
      listReductionTargetSelect: [],
      defaultValueSelect: '',
      reductionTargetDetail: {},
      showConfirmDelete: false,
      confirmMessageDelete: '',
      // confirmTextDelete: '削除',
      isSaveButtonDelete: true,
      showMoreBody: true,
      showListReductionTarge: false,
      isShowLoading: true,
      organizationList: [
        { id: 1, value: this.$t('reduction.label_single_company') },
        { id: 2, value: this.$t('reduction.label_subsidiaries_company') },
        { id: 3, value: this.$t('reduction.label_associated_company') },
        { id: 4, value: this.$t('reduction.label_associated_company_other') },
      ],
      emissionsTargetValue: 100,
      targetValueStandard: 100,
      showTooltip: false,
      dialogPopupUpdateSuccess: false,
      labelButtonAddTarget: this.$t('reduction.button_add_target'),
      selectDuration: 1,
      listSelectDurations: [
        { text: this.$t('icp_target_integration.title_year_reduction_period'), value: 1 },
        { text: this.$t('icp_target_integration.title_half_year_reduction_period'), value: 2 },
        { text: this.$t('icp_target_integration.title_quarter_year_reduction_period'), value: 3 },
        { text: this.$t('icp_target_integration.title_month_year_reduction_period'), value: 4 },
      ],
      dataBottomICP: {},
      startYear: null,
      endYear: null,
      idDetailTargetReduction: null,
      startMonth: null,
      dataBranch: [],
      detailBranchList: [],
      allDataType: []
    };
  },
  async mounted() {
    if (this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    const monthRes = await getStartMonth(this.$store.state.userData.contractor)
    this.startMonth = monthRes.data.start_month
    this.selectDuration = this.durationReductionTarget || 1; // 1 is the default selected year duration
    if (!this.planType.is_reduction_target) {
      this.$router.push({ path: ROUTES.HOME_DASHBOARD });
    }
    this.updateBreadCrumb(this.breadcrum);
    this.setLabelButtonAddTarget();
    window.addEventListener('resize', this.setLabelButtonAddTarget);
    this.detailBranchList = await getListBranchApi({contractor_id: this.currentUser.user?.contractor_id});
    const convertKey = ['business_name_ids', 'country_ids', 'layer_3_ids', 'layer_4_ids', 'layer_5_ids', 'layer_6_ids']
    this.dataBranch = this.detailBranchList.layer.filter(item => item.status).sort((firstLayer, nextLayer) => firstLayer.id - nextLayer.id).map(item => ({...item, new_key: convertKey[item.layer_index - 1]}));
    await this.handleGetDataListReductionTarget(true);
    let dataArray = [...this.listReductionTargetSelect];
    dataArray.sort((a, b) => new Date(b.created) - new Date(a.created));
    this.defaultValueSelect = dataArray[0];
    this.defaultValueSelect = this.reductionTarget && dataArray.find(item => item.value === this.reductionTarget) ? this.reductionTarget : dataArray[0];

    if (this.$route.query?.isUpdate === 'true') {
      this.dialogPopupUpdateSuccess = true;
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setLabelButtonAddTarget);
  },
  computed: {
    ...mapState('userData', ['planType', 'currentUser', 'isSettingPcaf']),
    ...mapState('reductionTarget', ['reductionTarget', 'durationReductionTarget']),
    labelEmission() {
      if (this.reductionTargetDetail?.emissions_value_accumulation?.emissions_actual_value) {
        return this.reductionTargetDetail.emissions_value_accumulation.compare_label > 0;
      }
      return;
    },
    titleText() {
      return this.$t('reduction.title_title_page');
    },
    labelBtnRegister() {
      return this.$t('reduction.button_add_target');
    },
    placeholderPulldownReduction() {
      return this.$t('reduction.placeholder_pulldown_select_reduction');
    },
    confirmTextDelete() {
      return this.$t('reduction.button_delete');
    },
    messageConfirmChange() {
      return this.$t('reduction.description_confirm_change');
    },
    cancelBtnText() {
      return this.$t('reduction.button_cancel');
    },
    getTitleTargetValueCurrentLabel() {
      const currentDate = getDateCurrent(parseInt(this.selectDuration) - 1, this.startMonth, true);
      const condition = (this.$i18n.locale === 'es' && this.selectDuration !== 1);
      const durationMap = {
        1: '',
        2: condition ?
          this.$t('icp_target_integration.prefix_year_month_es') + currentDate?.halfYear?.value :
          currentDate?.halfYear?.value,
        3: condition ?
          this.$t('icp_target_integration.prefix_year_month_es') + currentDate?.quarterYear?.value :
          currentDate?.quarterYear?.value,
        4: condition ?
          this.$t('icp_target_integration.prefix_year_month_es') + this.$t('icp_target_integration.title_month_year_reduction_period_month', { month: currentDate?.month }) :
          this.$t('icp_target_integration.title_month_year_reduction_period_month', { month: currentDate?.month }),
      };
      

      const subDuration = durationMap[this.selectDuration] || '';
      return this.$t('icp_target_integration.title_duration_select_term_rate', {
        year: currentDate?.year?.toString()?.toLowerCase(),
        subDuration: this.selectDuration === 3 ? subDuration?.toString() : subDuration?.toString()?.toLowerCase(),
      });
    },
    getTitleAchieveValueCurrentLabel() {
      const currentDate = getDateCurrent(parseInt(this.selectDuration) - 1, this.startMonth, true);
      const condition = (this.$i18n.locale === 'es' && this.selectDuration !== 1);

      const durationMap = {
        1: '',
        2: condition ? this.$t('icp_target_integration.prefix_year_month_es') + currentDate?.halfYear?.value :
            currentDate?.halfYear?.value,
        3: condition ? this.$t('icp_target_integration.prefix_year_month_es') + currentDate?.quarterYear?.value : currentDate?.quarterYear?.value,
        4: condition ? this.$t('icp_target_integration.prefix_year_month_es') + this.$t('icp_target_integration.title_month_year_reduction_period_month', { month: currentDate?.month }): 
             this.$t('icp_target_integration.title_month_year_reduction_period_month', { month: currentDate?.month }),
      };
      const subDuration = durationMap[this.selectDuration] || '';
      return this.$t('icp_target_integration.title_duration_select_term_rate_achieve', {
        year: currentDate?.year?.toString()?.toLowerCase(),
        subDuration: this.selectDuration === 3 ? subDuration?.toString() : subDuration?.toString()?.toLowerCase(),
      });
    },
    getStyleSelectDuration() {
      let widthItem = '83px'
      if(this.selectDuration) {
        const itemSelected = this.listSelectDurations.find((item) => this.selectDuration === item.value);
        const widthText = getWidthText(itemSelected.text, {fontSize: '14px', fontWeight: 500, letterSpacing: '0.03em' }) + 2; // 2 is plus px when active selection
        if(widthText + 60 > 89) { // The maximum width item is 89, and it cannot be changed.
          widthItem = `${widthText + 60}px` // The checkbox for component width is number 60.
        }
      }
      return { 'max-width': widthItem };
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('reductionTarget', ['setLastedReductionTarget', 'setDurationReductionTarget']),
    addDataForChartLine(reductionDetail) {
      // add year default
      let startYear = reductionDetail.start_year;
      let endYear = reductionDetail.end_year;
      const resultArray = [];
      for (let year = startYear; year <= endYear; year++) {
        resultArray.push({ year: String(year) });
      }
      this.allYearDefault = resultArray;

      // add data target year
      let permissionTargetYearObj = reductionDetail.target_year?.target_emissions
        ? JSON.parse(reductionDetail.target_year?.target_emissions)
        : {};
      let targetOffsets = reductionDetail.target_year?.target_offsets
        ? JSON.parse(reductionDetail.target_year?.target_offsets)
        : {};
      let permissionGHgObj = reductionDetail.emission_ghg_json ? reductionDetail.emission_ghg_json : {};
      this.dataAllLine = [
        {
          title: this.$t('reduction.label_reduction_target'),
          data: permissionTargetYearObj,
        },
        {
          title: ICP_TEXT,
          data: targetOffsets,
          isICP: true,
        },
        {
          title: this.$t('reduction.label_achievements'),
          data: permissionGHgObj,
        },
      ];

      // add data compare year
      reductionDetail.target_compare_year?.forEach((itemTargetCompareYear) => {
        let permissionCompareObj = JSON.parse(itemTargetCompareYear.target_emissions);
        this.dataAllLine = [
          ...this.dataAllLine,
          {
            title: itemTargetCompareYear.title,
            data: permissionCompareObj,
          },
        ];
      });
      // maping render to data for chart
      this.legends = this.dataAllLine.map((item, index) => ({
        ...item,
        text: item.title,
        borderColor: PALETTE_COLOR_REDUCTION[index],
      }));

      let dataMaping = [...cloneDeep(resultArray)];
      let result = dataMaping.map((itemB) => {
        const newObj = { year: itemB.year };
        this.dataAllLine.forEach((itemA) => {
          if (itemA.data[itemB.year]) {
            newObj[itemA.title] = +itemA.data[itemB.year];
            newObj[`${itemA.title}value`] = itemA.data[itemB.year];
          } else {
            newObj[itemA.title] = null;
          }
        });

        // Sắp xếp lại thuộc tính trong đối tượng theo thứ tự mong muốn
        const orderedObj = {};
        Object.keys(newObj)
          .sort()
          .forEach((key) => {
            orderedObj[key] = newObj[key];
          });

        return orderedObj;
      });

      // trim value = 0 end
      result = this.removeZero(result, true);

      // trim value = 0 start
      result = this.removeZero(result, false);

      //map data for charline
      result = this.hanldeMapDataForChartLine(result);
      this.dataForLineChart = result;
    },
    removeZero(array, isReverse) {
      const arrTmp = JSON.parse(JSON.stringify(isReverse ? array.reverse() : array));
      const keys = [
        ...new Set(
          array
            .map((item) => {
              return Object.keys(item);
            })
            .flat(Infinity),
        ),
      ];
      const keyEntries = [];
      const result = arrTmp.map((item) => {
        keys.forEach((key) => {
          if (item[key] === null && !keyEntries.includes(key)) delete item[key];
          else if (item[key] && !keyEntries.includes(key)) {
            keyEntries.push(key);
          }
        });
        return item;
      });
      return isReverse ? result.reverse() : result;
    },
    hanldeMapDataForChartLine(data) {
      const result = data.map((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && item[key] === null) {
            item[key] = FAKE_VALUE_ZERO;
          }
        }
        return item;
      });
      return result;
    },
    convertData(arrA, arrB) {
      const output = [];

      arrB.forEach((yearObj) => {
        const yearData = { year: yearObj.year };

        arrA.forEach((item) => {
          yearData[item.title] = item.data[yearObj.year];
        });

        output.push(yearData);
      });

      return output;
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    async handleSubmitDelete() {
      await deleteReductionTargetApi(this.reductionTargetDetail.id);
      await this.handleGetDataListReductionTarget(false);
      this.defaultValueSelect = this.listReductionTargetSelect[0];
      this.showConfirmDelete = false;
    },
    handleEdit() {
      this.$router.push({ path: `${ROUTES.PRODUCT_REDUCTION_SETTING}?id=${this.reductionTargetDetail.id}` });
    },
    handleSetting() {
      this.$router.push({ path: `${ROUTES.PRODUCT_REDUCTION_SETTING}` });
    },
    handleToggleMenu() {
      this.showMoreBody = !this.showMoreBody;
      const menu = document.querySelector('.target-setting-body');
      const header = document.querySelector('.target-setting-show-header');
      const button = document.querySelector('.target-setting-add-new');
      button.classList.add('active');
      setTimeout(function () {
        button.classList.remove('active');
      }, 200);

      if (this.showMoreBody) {
        menu.classList.remove('open-menu');
        menu.classList.add('closed-menu');
        setTimeout(function () {
          menu.style.display = 'none';
          header.classList.remove('boder-bottom');
        }, 200);
      } else {
        menu.classList.remove('closed-menu');
        menu.classList.add('open-menu');
        menu.style.display = window.innerWidth >= 1024 ? 'flex' : 'block';
        header.classList.add('boder-bottom');
      }
    },
    formatRoundingNumber(num, decimalPlaces = 2) {
      return roundingNumber(num, decimalPlaces);
    },
    getNumberOfElement(percent) {
      const result = 100 / (1 + percent / 100);
      if (result === 50) return 100;
      if (result === 100) return 0;
      return result;
    },
    getInfoBranch(branch, fieldName) {
      const information = branch.map((item) => item[fieldName]);
      return information;
    },
    getScopeCategories(pattern_ids, scopeCategoriesData) {
      return handleGetScopeCategories(pattern_ids, scopeCategoriesData, this.allDataType);
    },
    async handleChangeSelectDuration(value) {
      this.selectDuration = value;
      this.setDurationReductionTarget(value);
      await this.getReductionTargetDetail();
    },
    async handleChangeReductionTargetSelect(value) {
      this.idDetailTargetReduction = value;
      await this.getReductionTargetDetail();
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
    async getReductionTargetDetail() {
      const reductionTargetDetailApi = await getReductionTargetDetailApi(
        this.idDetailTargetReduction,
        this.selectDuration === 2 ||  this.selectDuration === 3 ? this.selectDuration + 1 : this.selectDuration === 4 ? 2 : 1,
      );
      const indexScope3Category15 = scopeCategoriesData.findIndex((item) => item.categories === 15);
      let dataScope = JSON.parse(JSON.stringify([...scopeCategoriesData]))
      // dataScope[indexScope3Category15].methods = [...oldmethod, ...newPcafMethod];
      let pcafMethod = newPcafMethod
      if (this.planType?.is_pcaf && !this.allDataType.length) {
        pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
      }
      dataScope[indexScope3Category15].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod;
      this.reductionTargetDetail = {
        ...reductionTargetDetailApi.data,
        list_scrope_categories: reductionTargetDetailApi.data.pattern_ids
          ? this.getScopeCategories(reductionTargetDetailApi.data.pattern_ids, dataScope).map((item) => item.text)
          : [],
        divion_id: reductionTargetDetailApi.data.branches?.organizational_division
          ? this.organizationList
              .filter((record) =>
                this.getInfoBranch(
                  reductionTargetDetailApi.data.branches?.organizational_division,
                  'organizational_division',
                ).includes(record.id),
              )
              .map((ele) => ele.id)
          : [],
        division: reductionTargetDetailApi.data.branches?.organizational_division
          ? this.organizationList
              .filter((record) =>
                this.getInfoBranch(
                  reductionTargetDetailApi.data.branches?.organizational_division,
                  'organizational_division',
                ).includes(record.id),
              )
              .map((ele) => ele.value)
          : [],
        company: reductionTargetDetailApi.data.branches.company_name
          ? this.getInfoBranch(reductionTargetDetailApi.data.branches.company_name, 'company_name')
          : [],
      };
      this.setDefaultLayerDataInDetail();

      this.emissionsTargetValue = this.getNumberOfElement(
        this.formatRoundingNumber(reductionTargetDetailApi.data.emissions_value_accumulation.percent_value),
      );
      this.targetValueStandard = this.getNumberOfElement(
        this.formatRoundingNumber(reductionTargetDetailApi.data.emission_value_reduce_yearly?.percent_target_value),
      );

      this.dataBottomICP = reductionTargetDetailApi.data.bottom_icp;
      this.startYear = reductionTargetDetailApi.data.start_year;
      this.endYear = reductionTargetDetailApi.data.end_year;

      this.dataAccumulation = [
        {
          brand: 'emissions_target_value',
          sales: this.reductionTargetDetail.emissions_value_accumulation?.percent_value,
        },
      ];

      this.dataReduceYearly = [
        { brand: 'target_value_standard', sales: this.targetValueStandard },
        { brand: 'target_value_cut', sales: 100 - this.targetValueStandard },
      ];
      const targetName = reductionTargetDetailApi.data.title;
      this.confirmMessageDelete = this.$t('reduction.description_confirm_delete', { targetName });
      this.addDataForChartLine(reductionTargetDetailApi.data);
      this.setLastedReductionTarget(this.idDetailTargetReduction);
    },
    async handleGetDataListReductionTarget(isCreated = false) {
      const dataListReductionTarget = await getListReductionTargetApi();
      this.isShowLoading = false;
      if (dataListReductionTarget.data.length > 0) {
        this.showListReductionTarge = true;

        this.listReductionTargetSelect = dataListReductionTarget.data.map((item) => {
          return { value: item.id, text: item.title, created: item.created_at };
        });

        let dataArraySelected = [...this.listReductionTargetSelect];
        dataArraySelected.sort((a, b) => new Date(b.created) - new Date(a.created));
        this.defaultValueSelect = dataArraySelected[0];
        let valueCalled = dataListReductionTarget.data[0].id;

        if (isCreated) {
          valueCalled = this.reductionTarget && dataArraySelected.find(item => item.value === this.reductionTarget) ? this.reductionTarget : dataArraySelected[0].value;
        }

        this.idDetailTargetReduction = valueCalled;
        this.getReductionTargetDetail();
      } else {
        this.showListReductionTarge = false;
      }
    },
    convertArrToStringList(arr) {
      return arr.join(', ');
    },
    uniqueCompanyName(inputArray) {
      const trimmedArray = inputArray.map((item) => item.trim());
      const uniqueSet = new Set(trimmedArray);
      return Array.from(uniqueSet);
    },
    emptyLable(inputArray) {
      return inputArray.map(item => item === null ? this.$t("register_data.checkbox_blank"): item)
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    handleCloseNotiPopup() {
      this.dialogPopupUpdateSuccess = false;
      this.$router.replace({ path: this.$route.path });
    },
    getLastUpdate(reductionTargetDetail) {
      return reductionTargetDetail?.latest_record?.updated_at_latest
        ? this.dateTimeFormat(reductionTargetDetail?.latest_record?.updated_at_latest)
        : '';
    },
    setLabelButtonAddTarget() {
      this.labelButtonAddTarget =
        window.innerWidth >= 1024 ? this.$t('reduction.button_add_target') : this.$t('reduction.button_add_target_sp');
    },
    formatNumberValue(number) {
      if (number === undefined || number === null) return '-';
      return addThoundSandCommaWithTwoDigit(number, "", false, true)
    },
    getFontSize(percent = '') {
      let fontSize = 30;
      const lengthPercent = percent?.length || 0;
      if (lengthPercent < 7) {
        fontSize = 30;
      } else if (lengthPercent >= 7 && lengthPercent < 12) {
        fontSize = 18;
      } else if (lengthPercent >= 12 && lengthPercent < 16) {
        fontSize = 12;
      } else if (lengthPercent >= 16) {
        fontSize = 9;
      }
      return `font-size: ${fontSize}px;`;
    },
    getStyleLegend(item) {
      return {
        backgroundColor: item?.isICP ? `#FFF` : item.borderColor,
        border: item?.isICP ? `1px solid ${item.borderColor}` : '',
      };
    },
    setDefaultLayerDataInDetail() {
      let defaultData = {
        'business_name_ids': [],
        'country_ids': [],
        'layer_3_ids': [],
        'layer_4_ids': [],
        'layer_5_ids': [],
        'layer_6_ids': [],
      };
      const itemData = {
        'business_name_ids': 'business_name',
        'country_ids': 'country',
        'layer_3_ids': 'layer_3',
        'layer_4_ids': 'layer_4',
        'layer_5_ids': 'layer_5',
        'layer_6_ids': 'layer_6',
      }
      Object.keys(defaultData).forEach(itemType => {
        if (this.reductionTargetDetail.branches[itemType].length > 0) {
          this.reductionTargetDetail[itemType] = this.getInfoBranch(this.reductionTargetDetail.branches[itemType], itemData[itemType]);
          delete defaultData[itemType];
        }
      })
      Object.keys(defaultData).forEach(itemType => {
        for (let i = 0; i < this.detailBranchList.data.length; i++) {
          const item = this.detailBranchList.data[i];
          if (!this.checkItemExist(itemType, item)) {
            continue;
          }
          switch (itemType) {
            case "business_name_ids":
              defaultData[itemType].push(item.business_name);
            break;
            case "country_ids":
              defaultData[itemType].push(item.country);
            break;
            case "layer_3_ids":
              defaultData[itemType].push(item.layer_3);
            break;
            case "layer_4_ids":
              defaultData[itemType].push(item.layer_4);
            break;
            case "layer_5_ids":
              defaultData[itemType].push(item.layer_5);
            break;
            case "layer_6_ids":
              defaultData[itemType].push(item.layer_6);
            break;
            default:
              break;
          }
        }
        this.reductionTargetDetail[itemType] = defaultData[itemType];
      })
    },
    checkItemExist(itemType, item) {
      const filterType = ['organizational', 'companies' ,'business_name_ids', 'country_ids', 'layer_3_ids', 'layer_4_ids', 'layer_5_ids', 'layer_6_ids'];
      const existIndex = filterType.indexOf(itemType);
      for (let i = 0; i < existIndex; i++) {
        if (!this.filterDataByIndex(filterType[i], item)) {
          return false;
        }
      }
      return true;
    },
    filterDataByIndex(type, item) {
      switch (type) {
        case "organizational":
          return this.reductionTargetDetail.divion_id.indexOf(item.organizational_division) < 0 ? false : true;
        case "companies":
          return this.reductionTargetDetail.company.indexOf(item.company_name) < 0 ? false : true;
        case "business_name_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.business_name === item.business_name) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.business_name) < 0 ? false: true;
          }
        case "country_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.country === item.country) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.country) < 0 ? false: true;
          }
        case "layer_3_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.layer_3 === item.layer_3) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.layer_3) < 0 ? false: true;
          }
        case "layer_4_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.layer_4 === item.layer_4) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.layer_4) < 0 ? false: true;
          }
        case "layer_5_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.layer_5 === item.layer_5) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.layer_5) < 0 ? false: true;
          }
        case "layer_6_ids":
          if (this.reductionTargetDetail.branches[type].length > 0) {
            // if exist branches data
            return this.reductionTargetDetail.branches[type].findIndex(itemData => itemData.layer_6 === item.layer_6) < 0 ? false: true;
          } else {
            return this.reductionTargetDetail[type].indexOf(item.layer_6) < 0 ? false: true;
          }
        default:
          return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$reduceTarget: #125add;
$achievement: $redMid;
$titleComparison: #599f5b;
$bg-main: #e3eded;
$textColor: #404d50;
$textColorDisable: var(--background-mid, #bfd4d9);
.cut-target-setting-header {
  height: 278px;
  background-color: $bg-main;
  text-align: center;
  margin-top: 68px;
  &--icon {
    padding-top: 40px;
  }
  &--note {
    margin-top: 16px;
    // font-family: Source Han Sans JP;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #7c898c;
    margin: 11px 20px 0px 20px;
    p {
      margin: 0;
    }
  }
  &--button {
    margin: 0 20px;
    .submit-register-btn {
      min-width: 323.33px !important;
      margin-top: 40px;
    }
    @media (max-width: 400px) {
      .submit-register-btn {
        min-width: 100% !important;
      }
    }
  }
}
.cut-target-setting-bottom {
  width: 100%;
  height: 160px;
  overflow: hidden;
  background: url('../../../assets/images/reduction/reduction-pie-charts.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.target-setting-show-header {
  min-height: 106px;
  padding: 16px;
  border-radius: 4px 4px 0px 0px;
  background-color: $bg-main;

  &.boder-bottom {
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
  }

  &--flag-icon {
    margin-bottom: 8px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $textColor;
    }
  }
  &--actions {
    display: flex;
    justify-content: space-between;

    &--reduction {
      display: flex;

      .btn-reduction {
        margin-left: 16px;
        width: 104px !important;
        height: 40px !important;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .select-reduction {
        width: 440px;
      }
    }
  }
}

.target-setting-add-new {
  width: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f2;
  border-radius: 4px;
  height: 40px;
  background: var(--mono-off-white, #f7f7f2);
  transition: 0.1s ease-out;
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  cursor: pointer;
  &:active {
    border: 2px solid $blueMid;
  }

  @include desktop {
    &:hover {
      background: #a4a184 !important;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48),
        0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31),
        0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24),
        0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      color: #fff;
      .target-setting-add-new--select {
        .active-icon {
          display: block;
        }
        .normal-icon {
          display: none;
        }
        .target-setting-add-new--select--label {
          color: #fff;
        }
      }
    }
  }

  &.active {
    background: #a4a184 !important;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
      0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
      0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
      0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
      0px 36px 33px 0px rgba(160, 181, 186, 0.07);
    color: #fff;
    .target-setting-add-new--select {
      .active-icon {
        display: block;
      }
      .normal-icon {
        display: none;
      }
      .target-setting-add-new--select--label {
        color: #fff;
      }
    }
  }

  .active-icon {
    display: none;
  }
  .normal-icon {
    display: block;
  }
  &--select {
    display: flex;
    align-items: center;
    justify-content: center;

    &--icon {
      margin-right: 8px;
    }
    &--label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $textColor;
      margin-bottom: 2px;
    }
  }
}
.target-setting-body {
  display: flex;
  justify-content: space-between;
  background-color: $bg-main;
  padding: 16px;
  min-height: 312px;
  gap: 32px;
  border-radius: 0px 0px 4px 4px;
  .target-setting-duration {
    display: flex;
    margin-bottom: 16px;
  }

  &.closed-menu {
    display: none;
  }

  &--left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &--box {
      span {
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $textColor;
      }
      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $goldMid;
        margin: 0;
      }
    }
  }
  &--right {
    display: flex;
    flex-direction: column;
    // gap: 30px;
    justify-content: space-between;
    .wrap__status {
      margin-bottom: 0px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 15px;
      padding-right: 40px;
      flex-direction: column;
      &__item {
        display: flex;
        align-items: center;
      }

      .wrap__status__item__wrap {
        margin-left: 12px;
        p {
          color: $textColor;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;
        }
        p:nth-child(1) {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
        }
      }
    }
  }
}
.setting-actions-reduce {
  margin-top: 12px;
}

.helper-icon {
  margin-left: 8px;
  line-height: 14px;

  &.tooltip {
    position: absolute;
    width: 400px;
    height: unset;
    max-width: unset;
    display: block;
    background: #ffffff;
    white-space: break-spaces;
    border-radius: 4px;
    color: $textColor;
    margin: 0 auto;
    font-size: 15px;
    line-height: 20px;
    padding: 10px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}

.pie-chart-disable {
  width: 136px;
  height: 136px;
  position: relative;

  .percentage-label-inchart {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: $textColorDisable;
    text-align: right;
    font-family: Century Gothic Pro;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 120% */
    letter-spacing: 0.9px;

    .percent-unit-inchart {
      color: $textColorDisable;
      // font-family: Source Han Sans JP;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px; /* 171.429% */
      letter-spacing: 0.7px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.pie-chart {
  min-height: 256px;
  padding: 24px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  .pie-chart-box {
    width: 33.33333%;
    position: relative;

    .pie-chart-box-comingsoon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $textColor;
      margin-bottom: 24px;
      white-space: nowrap;
      gap: 16px;
      min-height: 40px;
      flex-wrap: nowrap;
      .select-duration {
        min-width: 83px;
        max-width: 100px;
      }
      .title-chart {
        white-space: break-spaces;
      }
      &--text {
        white-space: pre-line;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $monoBlack;
      }
      .button-active {
        height: 28px;
        padding: 4px 8px 6px 8px;
        border-radius: 2px;
        gap: 4px;
        background-color: $greenDark;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
      }

      .button-disable {
        height: 24px;
        margin-left: 16px;
        color: #99a6a9;
        font-size: 11px;
        font-weight: 700;
        line-height: 18px; /* 163.636% */
        letter-spacing: 0.33px;
        display: flex;
        padding: 0px 6.5px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 2px;
        border: 1px solid var(--devider-black, rgba(42, 42, 48, 0.1));
        white-space: nowrap;
      }
    }

    .text-color-disable {
      color: $monoBlack !important;
      margin-top: 32px;
    }
    .text-color-bottom-disable {
      margin-top: 16px;
    }
    .pie-chart-box-wrap {
      display: flex;

      .pie-chart-container-chart {
        // margin-right: 8px;
        width: 136px;
        height: 136px;
      }

      .pie-chart-container-content {
        position: relative;

        .pie-chart-container-content-percent {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          top: -6px;
          // left: -12px;

          .pie-chart-container-content-percent-icon {
            margin-left: 4px;
            display: flex;
            align-items: flex-end;
            color: var(--gold-mid-100, $goldMid);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            position: relative;
            bottom: -7px;
          }

          .pie-chart-container-content-percent-value {
            color: var(--gold-mid-100, $goldMid);
            text-align: right;
            display: flex;
            align-items: flex-end;
            font-size: 28px !important;
            font-weight: 400;
            line-height: 36px;
            margin-bottom: 0;
          }
        }
      }

      .text-color-disable {
        color: $textColorDisable !important;
      }
    }

    .pie-chart-content.left-shift {
      position: relative;
    }

    .pie-chart-content {
      margin-top: 10px;
      &.pie-chart-content-disable {
        margin-top: 10px;
      }
      .pie-chart-content-top {
        border-bottom: 1px solid #ccc;
      }
      .pie-chart-content-bottom {
        margin-top: 8px;
        .values-text.sub-value {
          margin-bottom: 0px;
        }
        .revert-icon {
          transform: rotate(180deg);
          transition: transform 0.3s ease;
        }
      }
      .pie-chart-achievement {
        margin-left: 16px;
      }
      .title-text {
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $textColor;
        margin-bottom: 8px;
      }
      .values-top {
        margin-bottom: 15px !important;
      }
      .values-text {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.05em;
        text-align: left;
        color: $goldMid;
        margin-bottom: 8px;
        word-break: break-all;
        .unit-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.05em;
          text-align: left;
          display: inline-block;
        }
      }
      .sub-value {
        font-size: 14px;
        .unit-text {
          font-size: 11px;
        }
      }
    }
  }
  .pie-chart-box:nth-child(2) {
    // min-width: fit-content;
    margin-right: 16px;
  }
  .prediction-reduction {
    width: 25%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .helper-icon {
    &.tooltip {
      left: 0;
      width: auto;
      max-width: unset;
    }
  }
}

@include desktop {
  .cut-target-setting-bottom {
    height: 240px;
  }
  .pie-chart-content {
    margin-top: 9px !important;
  }

  .pie-chart {
    .pie-chart-box {
      .pie-chart-content.left-shift {
        position: relative;
        // width: calc(100% - 136px);
        // left: -24px;
      }
      .pie-chart-content {
        .pie-chart-content-top {
          padding-left: 24px;
        }
        .pie-chart-content-bottom {
          margin-top: 8px;
          padding-left: 24px;
        }
      }
    }
  }
  .text-color-disable {
    margin-top: 0 !important;
  }

  .text-color-bottom-disable {
    margin-top: 0 !important;
  }
}

.prediction-reduction {
  .prediction-reduction-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
    color: $textColor;
    margin: 0;
  }

  .prediction-reduction-box {
    display: flex;
    flex-direction: column;
    &__title {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.03em;
      text-align: left;
      color: $textColor;
      margin-bottom: 8px;
    }
    .prediction-reduction-box-values {
      height: 44px;
      padding: 8px 8px 8px 16px;
      border-radius: 4px;
      gap: 8px;
      background-color: #eff5f5;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        color: $goldMid;
      }
      button {
        height: 28px;
        padding: 4px 8px 6px 8px;
        border-radius: 2px;
        gap: 4px;
        background-color: $greenDark;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .prediction-reduction-box-btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        min-width: 60px;

        .unit-carbonix {
          font-size: 11px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.05em;
          text-align: center;
        }
      }
    }
  }
  .prediction-reduction:nth-child(2) {
    margin-bottom: 12px;
  }
}

@media (max-width: 1025px) {
  .target-setting-show-header--actions {
    display: block !important;
  }
  .target-setting-add-new {
    margin-top: 16px;
    width: 100% !important;
  }
  .target-setting-show {
    .target-setting-body {
      display: block;
    }
  }
  .target-setting-body--right {
    margin-top: 32px;
  }
  .target-setting-body--right .wrap__status {
    gap: 16px;
  }
  .setting-actions-reduce {
    margin: 0 !important;
  }
  .pie-chart {
    display: block !important;
  }
  .pie-chart-box-wrap {
    display: block !important;
  }
  .pie-chart-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &.margin-center-icp {
      margin: 0 auto;
    }
  }
  .values-text {
    text-align: right !important;
  }
  .prediction-reduction,
  .pie-chart-box {
    width: 100% !important;
  }

  .pie-chart {
    .pie-chart-box {
      &__title {
        justify-content: space-between;
        &.unset-justify-content {
          justify-content: unset;
        }
      }
    }
    .padding-left-none {
      padding-left: 0 !important;
    }
  }
}
.assets-type-list {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 48px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  .assets-type-item {
    margin-right: 8px;
  }
  .assets-point {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;
  }
  .assets-title {
    font-size: 11px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: rgba(64, 77, 80, 1);
  }
}
.target-setting-body {
  transform-origin: top center;
  animation: openMenuAnimation 300ms ease-in-out forwards;
}

@keyframes openMenuAnimation {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes closeMenuAnimation {
  0% {
    opacity: 1;
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
}

.target-setting-body {
  &.closed-menu {
    animation: closeMenuAnimation 200ms ease-in-out forwards;
  }
}
.pulldown-icon {
  display: inline-block;
  transform: rotate(180deg);
}
.pulldown-icon.open {
  transform: rotate(0deg);
}
</style>
