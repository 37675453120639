<template>
  <div class="doughnut-chart">
    <svg :viewBox="viewBox" xmlns="http://www.w3.org/2000/svg">
      <circle
        :r="outerRadius"
        :cx="center"
        :cy="center"
        :fill="backgroundColor"
      />
      <path :d="pathData" :fill="progressColor" />
      <circle
        :r="innerRadius"
        :cx="center"
        :cy="center"
        :fill="innerBackgroundColor"
      />
    </svg>
    <div class="percentage-label">
      <div class="percent-data" :style="getFontSize(formatNumberValue(percentage))">{{ formatNumberValue(percentage) }}</div>
      <div class="percent-unit">%</div>
    </div>
  </div>
</template>

<script>
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
export default {
  props: {
    outerRadius: {
      type: Number,
      required: true,
    },
    innerRadius: {
      type: Number,
      required: true,
    },
    innerBackgroundColor: {
      type: String,
      default: 'white',
    },
    backgroundColor: {
      type: String,
      default: '#EEF0F0',
    },
    progressColor: {
      type: String,
      default: '#89B473',
    },
    percentage: {
      type: Number,
      required: true,
    }
  },
  computed: {
    center() {
      return this.outerRadius
    },
    viewBox() {
      const size = this.outerRadius * 2
      return `0 0 ${size} ${size}`
    },
    pathData() {
      const startAngle = -90
      const endAngle = (360 *  Math.abs(this.percentage)) / 100 - 90
      const x1 =
        this.center + this.outerRadius * Math.cos((Math.PI / 180) * startAngle)
      const y1 =
        this.center + this.outerRadius * Math.sin((Math.PI / 180) * startAngle)
      const x2 =
        this.center + this.outerRadius * Math.cos((Math.PI / 180) * endAngle)
      const y2 =
        this.center + this.outerRadius * Math.sin((Math.PI / 180) * endAngle)
      const arcs = Math.abs(this.percentage) > 50 ? '0 1 1' : '0 0 1'
      return `M ${x1} ${y1} A ${this.outerRadius} ${this.outerRadius} ${arcs} ${x2} ${y2} L ${this.center} ${this.center} Z`
    },
  },
  methods: {
    formatNumberValue(number) {
      if (number === 0) return "0.00";
      if(!number) return '-';
      return addThoundSandCommaWithTwoDigit(number);
    },
    getFontSize(percent = "") {
      let fontSize = 30
      const lengthPercent = percent?.length || 0
      if(lengthPercent < 7) {
        fontSize = 30
      } else if(lengthPercent >= 7 && lengthPercent < 12) {
        fontSize = 18
      } else if(lengthPercent >= 12  && lengthPercent < 16) {
        fontSize = 12
      } else if(lengthPercent >= 16 && lengthPercent < 19) {
        fontSize = 9
      } else if(lengthPercent >= 19) {
        fontSize = 6
      }
      return `font-size: ${fontSize}px;`
    }
  }
}
</script>
<style lang="scss" scoped>
.doughnut-chart {
  display: inline-block;
  position: relative;
  width: 136px;
  height: 136px;
  .percentage-label {
    font-family: 'Source Han Sans';
    color: $goldMid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: baseline;
    .percent-data {
      font-size: 30px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.9px;
    }
    .percent-unit {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.7px;
    }
  }
}
</style>
