<template>
  <div class="reduction-chart">
    <div class="line-chart-actions-top" style="height: 24px"></div>
    <div class="line-chart-actions-top">
      <button
        class="btn-action btn-prev"
        @click="prev"
        @mouseover="isMouseOverPrev = true"
        @mouseleave="isMouseOverPrev = false"
        @mousedown="isBlurPrev = true"
        @mouseup="isBlurPrev = false"
        :disabled="isDisabledPrev"
      >
        <img :src="prevIcon" alt="Prev" />
      </button>
      <button
        class="btn-action btn-next"
        @click="next"
        @mouseover="isMouseOverNext = true"
        @mouseleave="isMouseOverNext = false"
        @mousedown="isBlurNext = true"
        @mouseup="isBlurNext = false"
        :disabled="isDisabledNext"
      >
        <img :src="nextIcon" alt="Next" />
      </button>
    </div>

    <div class="chart line-chart line-chart-year">
      <wj-flex-chart
        ref="chart"
        :key="key"
        chartType="LineSymbols"
        :dataLabel="false"
        :itemsSource="data"
        :initialized="onChartInitialized"
        :tooltipContent="customTooltip"
        :rendered="onChartRendered"
        :palette="palette"
      >
        <wj-flex-chart-animation
          :initialized="initializeAnimation"
          :easing="easing"
          :duration="500"
        ></wj-flex-chart-animation>
      </wj-flex-chart>
      <div class="selected-div" :style="selectedDivStyle"></div>
      <div class="hover-div" :style="hoverDivStyle"></div>
      <!-- 6 is padding of chart -->
      <div
        class="bottom_icp"
        :style="{ marginLeft: `${widthXAxisBBox.x - 6}px` }"
        id="style-scroll"
        v-if="widthXAxisBBox.width"
      >
        <div class="year-labels">
          <div class="year-content">
            <div
              v-for="(item, index) in yearLabels.slice(pageCurrent, MAX_CHART_ITEMS + pageCurrent)"
              :key="index"
              :style="{ width: widthXAxisBBox.width / MAX_CHART_ITEMS + 'px' }"
              class="year-item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="ipc-container ipc-1">
          <div class="line-chart-actions-bottom">
            <button
              class="btn-action btn-prev"
              @click="prev"
              @mouseover="isMouseOverPrev = true"
              @mouseleave="isMouseOverPrev = false"
              @mousedown="isBlurPrev = true"
              @mouseup="isBlurPrev = false"
              :disabled="isDisabledPrev"
            >
              <img :src="prevIcon" alt="Prev" />
            </button>
            <button
              class="btn-action btn-next"
              @click="next"
              @mouseover="isMouseOverNext = true"
              @mouseleave="isMouseOverNext = false"
              @mousedown="isBlurNext = true"
              @mouseup="isBlurNext = false"
              :disabled="isDisabledNext"
            >
              <img :src="nextIcon" alt="Next" />
            </button>
          </div>
          <div class="icp-content" :style="{ width: widthXAxisBBox.width }">
            <div
              v-for="(item, index) in actualValues.slice(pageCurrent, MAX_CHART_ITEMS + pageCurrent)"
              :key="index"
              class="icp-item"
              :style="{ width: widthXAxisBBox.width / MAX_CHART_ITEMS + 'px' }"
              :class="{ blur: item.type === 2 }"
            >
              <div class="icp-item-container">
                <div :class="getClassForType(item.type)" class="title-icp">{{ getLabelForType(item.type) }}</div>
                <div class="values">
                  <span class="name">{{ $t('icp_target_integration.title_bottom_icp_discharge') }}</span>
                  <span class="value">{{ getEmissionNoAchieved(item, 'value') }}</span>
                </div>
                <div class="values">
                  <span class="name">{{ $t('icp_target_integration.title_bottom_icp_text') }}</span>
                  <span class="value">{{ getEmissionNoAchieved(item, 'emissions') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ipc-container">
          <div class="icp-content" :style="{ width: widthXAxisBBox.width }">
            <div
              v-for="(item, index) in noAchieveValues.slice(pageCurrent, MAX_CHART_ITEMS + pageCurrent)"
              :key="index"
              class="icp-item"
              :style="{ width: widthXAxisBBox.width / MAX_CHART_ITEMS + 'px' }"
              :class="{ blur: item.type === 2 }"
            >
              <div class="icp-item-container">
                <div :class="getClassForType(item.type)" class="title-icp">{{ getLabelForType(item.type) }}</div>
                <div class="values">
                  <span class="name">{{ $t('icp_target_integration.title_bottom_icp_discharge') }}</span>
                  <span class="value">{{ getEmissionNoAchieved(item, 'value') }}</span>
                </div>
                <div class="values">
                  <span class="name"
                    >{{ getSubTitleForType(item.type) }}
                    <v-tooltip v-if="item.type === 4" min-width="250px" max-width="300px" top>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="question" v-bind="attrs" v-on="on">
                          <img
                            @mouseover="changeTooltipImage($event)"
                            @mouseleave="changeTooltipImage($event, 'mouseleave')"
                            @click.stop="changeTooltipImage($event)"
                            src="img/icons/question.svg"
                            alt=""
                          />
                        </div>
                      </template>

                      <span class="title-tooltip">{{
                        $t('icp_target_integration.title_tooltip_required_reduction_amount')
                      }}</span>
                      <span class="sub-title-tooltip">{{
                        $t('icp_target_integration.title_tooltip_required_reduction_amount_detail')
                      }}</span>
                    </v-tooltip>
                  </span>
                  <span class="value">{{ getEmissionNoAchieved(item, 'emissions') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.chart.animation';
import '@mescius/wijmo.styles/wijmo.css';
import { Series } from '@mescius/wijmo.chart';
import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { addThoundSandCommaWithTwoDigit } from '@/utils/convertNumber';
import { PALETTE_COLOR_REDUCTION, FAKE_VALUE_ZERO, ICP_TEXT, ICP_BOTTOM_TYPE, FILL_COLOR_ICP } from '@/constants/products/define-data';
import moment from 'moment';
import { prepareChartAxisYLineChart } from '@/concerns/chart';
import { numberFormat } from '@/filters/number';
const LABEL_PADDING_X = 7;
const LABEL_PADDING_Y = 14;
const LABEL_PADDING_MOBILE_Y = 7;
const LABEL_ANGLE = -45;
export default {
  props: {
    branchIds: {
      type: Array,
      default: () => [],
    },
    paletteColor: {
      type: Array,
      default: () => [],
    },
    dataAllLineChart: {
      type: Array,
      default: () => [],
    },
    dataYearDefault: {
      type: Array,
      default: () => [],
    },
    productsMasterList: {
      type: Array,
      default: () => [],
    },
    productMasterDefault: {
      type: Array,
      default: () => [],
    },
    durationList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
    },
    isHasBranchNull: {
      type: Boolean,
      default: false,
    },
    dataBottomICP: {
      type: Object,
      default: () => {},
    },
    startYear: {
      type: Number | String,
      default: null,
    },
    endYear: {
      type: Number | String,
      default: null,
    },
    startMonth: {
      type: Number | String,
      default: null,
    }
  },
  data() {
    return {
      easing: 'EaseInQuad',
      animationMode: 'Point',
      min: 0,
      max: 500,
      axisXItemsSource: [],
      chartValue: [],
      selectedTimeRange: '',
      hoverTimeRange: '',
      isDisabledPrev: true,
      isDisabledNext: true,
      isMouseOverNext: false,
      isMouseOverPrev: false,
      isBlurNext: false,
      isBlurPrev: false,
      isFirstRender: true,
      transitionWidth: '100%',
      footerWidth: 0,
      minWidth: 0,
      data: [],
      breakDownData: [],
      MAX_CHART_ITEMS: 6,
      selectionStart: null,
      selectionEnd: null,
      chart: null,
      selectedDivStyle: {},
      hoverDivStyle: {},
      previousRectElement: null,
      palette: PALETTE_COLOR_REDUCTION,
      rawData: [],
      key: 0,
      pageCurrent: 0,
      isMobile: false,
      widthXAxisBBox: 0,
      actualValues: [],
      noAchieveValues: [],
      yearLabels: [],
    };
  },
  created() {
    this.renderDataFromCompareYear();
  },
  computed: {
    ...mapState('product', ['dashboard', 'colors']),
    ...mapState('userData', ['language']),
    nextIcon() {
      if (this.isDisabledNext) {
        this.isBlurNext = false;
        this.isMouseOverNext = false;
        return 'img/icons/next-disabled.svg';
      }

      if (this.isBlurNext) {
        return 'img/icons/next.svg';
      }

      if (this.isMouseOverNext) {
        return 'img/icons/next-hover.svg';
      }

      return 'img/icons/next.svg';
    },
    prevIcon() {
      if (this.isDisabledPrev) {
        this.isBlurPrev = false;
        this.isMouseOverPrev = false;
        return 'img/icons/prev-disabled.svg';
      }

      if (this.isBlurPrev) {
        return 'img/icons/prev.svg';
      }

      if (this.isMouseOverPrev) {
        return 'img/icons/prev-hover.svg';
      }

      return 'img/icons/prev.svg';
    },
    hasNegativeNumber() {
      const existNegativeNumber = this.chartValue.find(
        (item) => item.emission_scope1 < 0 || item.emission_scope2 < 0 || item.emission_scope3 < 0,
      );
      return existNegativeNumber ? true : false;
    },
    getColor() {
      const productSelect =
        this.productsMasterList.length <= 0 ? this.productMasterDefault.length : this.productsMasterList.length;
      return this.colors.slice(0, productSelect);
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);
    this.checkMobile();
  },
  destroyed() {
    this.handleOffEvent();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    ...mapActions('product', ['updateEmissionsByProducts']),
    getClassForType(type) {
      return ICP_BOTTOM_TYPE[type].className;
    },
    getLabelForType(type) {
      return ICP_BOTTOM_TYPE[type].label;
    },
    getSubTitleForType(type) {
      return type === 4
        ? this.$t('icp_target_integration.title_bottom_icp_yearly')
        : this.$t('icp_target_integration.title_bottom_icp_text');
    },
    getEmissionNoAchieved(item, type = 'value') {
      const formatValue = this.formatNumberValue(item[type]);
      if (formatValue === '-') {
        return formatValue;
      }
      if (type === 'value') {
        return `${formatValue} tCO2`;
      }
      switch (item.type) {
        case 1:
        case 3:
          return `¥${formatValue}`;
        case 4:
          return `${formatValue} tCO2`;
        default:
          return formatValue;
      }
    },
    transferDataBottom_Icp() {
      let index = 0;
      for (let year = this.startYear; year <= this.endYear; year++) {
        console.log(this.language);
        let yearLabel = `${year} ${this.$t('dashboard_main.label_year')}`;
        if(this.language?.code === 'vi') {
          yearLabel = `${this.$t('dashboard_main.label_year')} ${year}`;
        }
        this.yearLabels[index] = yearLabel;
        const actual = this.dataBottomICP[year]?.actual || {};
        const noAchieve = this.dataBottomICP[year]?.no_achieve || {};

        this.actualValues[index] = actual;
        this.noAchieveValues[index] = noAchieve;
        this.setTypesForValues(actual, noAchieve, year, index);
        index++;
      }
    },
    setTypesForValues(actual, noAchieve, year, index) {
      // type 1 ,2 ,3 ,4 ~ with classes actual-present, actual-future, no-achieve-present , no-achieve-future
      let currentYear = moment().year();
      let currentMonth = moment().month() + 1;
      if (this.startMonth > currentMonth) {
        currentYear = currentYear - 1;
      }
      if (parseInt(year) <= currentYear) {
        this.actualValues[index].type = 1;
        this.noAchieveValues[index].type = 3;
        if (parseInt(year) === currentYear ) {
          this.noAchieveValues[index].type = 4;
        }
      } else {
        this.actualValues[index].type = 2;
        this.noAchieveValues[index].type = 4;
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth < 480;
    },
    renderDataFromCompareYear() {
      this.breakDownData = this.dataAllLineChart;
      this.statusBtn();
      this.prepareChartDataValue();
      this.transferDataBottom_Icp();
    },
    statusBtn() {
      if (this.pageCurrent === 0) {
        this.isDisabledNext = this.breakDownData.length <= this.MAX_CHART_ITEMS;
        this.isDisabledPrev = true;
      } else {
        this.isDisabledNext = this.pageCurrent + this.MAX_CHART_ITEMS >= this.breakDownData.length;
        this.isDisabledPrev = false;
      }
    },
    initializeAnimation(animation) {
      this.chartAnimation = animation;
      this.chartAnimation.animationMode = this.animationMode;
    },
    onMouseOver(item) {
      this.hoverTimeRange = item.time_range;
    },
    onMouseLeave() {
      this.hoverTimeRange = '';
    },
    prepareChartDataValue() {
      let getTotalEmissionsAtIndex0 = [];
      if (this.pageCurrent !== 0) {
        //get length 5 data chart
        this.data = this.breakDownData.slice();
        let index = this.pageCurrent - 1;

        const dataKeys = this.paletteColor.map((item) => {
          return item.text;
        });
        const mediumScore = dataKeys?.reduce((result, key) => {
          result[key.toString()] = (this.data[index][key] + this.data[index + 1][key]) / 2;
          if ($_helper_isNumberType(key.toString())) {
            getTotalEmissionsAtIndex0.push((this.data[index][key] + this.data[index + 1][key]) / 2);
          }
          return result;
        }, {});

        this.data.splice(0, this.pageCurrent);
        this.data.unshift(mediumScore);
        this.data = [...this.data.slice(0, this.MAX_CHART_ITEMS + 1)];
      } else {
        //get length 4 data chart
        this.data = [...this.breakDownData.slice(0, this.MAX_CHART_ITEMS)];
      }

      this.addClassNameToRemoveSymbol();
      let totalsEmission = this.data
        .map((obj) => [...Object.values(obj)])
        .flat()
        .filter((item) => item && typeof item == 'number');
      this.updateAxisYChart(totalsEmission);
    },
    addClassNameToRemoveSymbol() {
      if (this.chart) {
        const el = this.$refs.chart.$el;
        if (el.classList.contains('remove-symbol-size')) {
          el.classList.remove('remove-symbol-size');
        }
        if (this.pageCurrent !== 0) {
          if (!el.classList.contains('remove-symbol-size')) {
            el.classList.add('remove-symbol-size');
          }
        }
      }
    },
    next() {
      if (this.pageCurrent + this.MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
        this.isDisabledPrev = false;
        return;
      }

      this.isDisabledNext = false;
      this.pageCurrent = this.pageCurrent + 1;
      if (this.pageCurrent + this.MAX_CHART_ITEMS >= this.breakDownData.length) {
        this.isDisabledNext = true;
      }
      this.prepareChartDataValue();
      if (this.pageCurrent > 0) {
        this.isDisabledPrev = false;
      } else {
        this.isDisabledPrev = true;
      }
    },
    prev() {
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
        return;
      }
      this.isDisabledPrev = false;
      this.pageCurrent = this.pageCurrent - 1;
      this.prepareChartDataValue();
      if (this.pageCurrent <= 0) {
        this.isDisabledPrev = true;
      }

      if (this.pageCurrent + this.MAX_CHART_ITEMS < this.breakDownData.length) {
        this.isDisabledNext = false;
      }
    },
    transferData(data) {},
    onChartInitialized(flexchart) {
      this.chart = flexchart;
      flexchart.bindingX = 'year';
      flexchart.legend.position = 0; // remove chart legend
      flexchart.symbolSize = 8;
      flexchart.axisX.majorTickMarks = false;
      flexchart.axisY.axisLine = true;
      flexchart.axisY.majorGrid = false;
      flexchart.axisX.max = this.MAX_CHART_ITEMS - 0.5;
      const chartEl = flexchart.hostElement;
      this.handleEventChart(chartEl);
      flexchart.axisY.labelPadding = LABEL_PADDING_X;
      flexchart.axisX.labelPadding = LABEL_PADDING_Y;
      flexchart.axisX.labelAngle = this.isMobile ? LABEL_ANGLE : 0;
      const el = this.$refs.chart.$el;
      if (this.pageCurrent && this.pageCurrent !== 0) {
        el.classList.add('remove-symbol-size');
      }
      flexchart.itemFormatter = (engine, hitTestInfo, defaultRenderer) => {
        if (hitTestInfo.name === ICP_TEXT) {
          engine.fill = FILL_COLOR_ICP;
          engine.strokeWidth = 1;
        }
        // Render the slice using the default renderer
        defaultRenderer();
      };
      this.updateInfoChart();
    },
    handleEventChart(chartEl) {
      chartEl.addEventListener('mouseleave', this.mouseLeaveEvent);
      chartEl.addEventListener('click', this.clickEvent);
      chartEl.addEventListener('mousemove', this.hoverEvent);
    },
    handleOffEvent() {
      const chartEl = this.chart.hostElement;

      if (chartEl) {
        chartEl.removeEventListener('mouseleave', this.mouseLeaveEvent);
        chartEl.removeEventListener('click', this.clickEvent);
        chartEl.removeEventListener('mousemove', this.hoverEvent);
      }
    },
    formatNumber(num) {
      return num === 0 ? num : Number(formatValue(num));
    },
    updateInfoChart() {
      const newSeriesConfigurations = this.paletteColor.map((item) => {
        return { binding: item.text, name: item.text, cssClass: item.text === ICP_TEXT ? 'dash-stroke' : '' };
      });
      newSeriesConfigurations.forEach((item) => {
        this.chart.series.push(new Series(item));
      });
      let totalsEmission = [...this.breakDownData.slice(0, this.MAX_CHART_ITEMS)]
        .map((obj) => [...Object.values(obj)])
        .flat()
        .filter((item) => typeof item == 'number');
      this.updateAxisYChart(totalsEmission);
    },
    onChartRendered(flexchart, args) {
      flexchart.axisY.labelPadding = LABEL_PADDING_X;
      flexchart.axisX.labelPadding = this.isMobile ? LABEL_PADDING_MOBILE_Y : LABEL_PADDING_Y;
      flexchart.axisX.labelAngle = this.isMobile ? LABEL_ANGLE : 0;
      const isExistRects = flexchart.axisX._rects;
      if (isExistRects) {
        this.updateYAxisRange();
      }
      if (this.pageCurrent !== 0) {
        this.chart.axisX.min = 0.5;
        this.chart.axisX.max = undefined;
      } else {
        this.chart.axisX.min = undefined;
        this.chart.axisX.max = this.MAX_CHART_ITEMS - 0.5;
      }

      const xAxisElement = flexchart.hostElement.querySelector('.wj-axis-x');

      // Get the width of the X-axis
      const xAxisWidth = xAxisElement?.getBBox();

      this.widthXAxisBBox = xAxisWidth;
    },
    updateIndexSelectedToSore(indexSelected, item) {},
    mouseLeaveEvent(e) {
      this.hoverDivStyle = {
        ...this.hoverDivStyle,
        display: 'none',
      };
    },
    clickEvent(e) {},
    hoverEvent(e) {},
    formatNumberCustome(num) {
      if (num === FAKE_VALUE_ZERO) return '-';
      return addThoundSandCommaWithTwoDigit(num, '', false, true);
    },
    customTooltip(ht) {
      const item = ht.item;
      if (!item.year) return '';
      let name = item['item' + ht.item.year];
      if (ht?.value === 0 && !name) {
        name = this.productMasterDefault[0]?.name || '';
      }
      const value = ht.item[`${ht.name}value`];
      return `<div style='display: flex;gap: 4px;flex-direction: column;padding: 4px; min-width: 131px; width: 100%;font-family: 'Source Han Sans';'>
                  <div style="color: #A9A04B;letter-spacing: 0.03em;line-height: 18px;font-size: 11px;font-weight: 700;">${
                    ht?.name
                  }</div>
                  <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;width: 100%;min-width: 131px;">
                    <div style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 62px;'>${this.$t(
                      'reduction.label_period',
                    )}</div>
                    <div style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.$t(
                      'register_reduction.fiscal_year',
                      { year: item?.year },
                    )}</div>
                  </div>
                  <div style="display: flex;justify-center: space-between; align-items: center; gap: 8px;min-width: 100%;">
                    <span style='font-weight: 700;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;min-width: 62px;'>${this.$t(
                      'reduction.label_emissions',
                    )}</span>
                    <span style='font-weight: 500;font-size: 11px;line-height: 18px;letter-spacing: 0.03em;color: #404D50;'>${this.formatNumberCustome(
                      value
                    )} t-CO2 ${this.$t('icp_target_integration.title_tooltip_prediction')}</span>
                  </div>
                </div>`;
    },
    updateYAxisRange() {},
    addSelectedClass(index) {
      const axisXElement = this.$el.querySelectorAll('.wj-axis-x .wj-label');
      for (let i = 0; i < axisXElement.length; i++) {
        axisXElement[i]?.classList.remove('active-select');
      }
      axisXElement[index]?.classList.add('active-select');
    },
    updateAxisYChart(dataCalc) {
      if (!this.chart) {
        return;
      }
      const calcAxisY = prepareChartAxisYLineChart(dataCalc, 7);
      this.chart.axisY.majorUnit = calcAxisY[2];
      this.chart.axisY.min = calcAxisY[0];
      this.chart.axisY.max = calcAxisY[1];
      this.max = calcAxisY[1];

      this.chart.axisY.itemFormatter = (engine, label) => {
        if (label.val >= (this.max)) {
         label.text = 't-CO2';
          label.cls += ' unit';
        }
        else {
          label.text = label.val === 0 ? '0' : numberFormat(label.val);
        }
        return label;
      };
    },
    formatNumberValue(number) {
      if (!number) return '-';
      return addThoundSandCommaWithTwoDigit(number, "", false, true)
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = 'img/icons/question.svg';
      } else {
        event.target.src = 'img/icons/question-active.svg';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/views/dashboard/components/emissions-by-period/yearly/chart-2/index.scss';

.line-chart-year {
  position: relative;
}
.year-labels {
  display: flex;
  align-items: center;
  width: 100%;
  .year-content {
    display: flex;
  }
  .year-item {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    color: #404d50;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.33px;
    padding-top: 8px;
    // max-width: 300px;
    &:not(:nth-last-child(1)) {
      border-right: 1px solid rgba(42, 42, 48, 0.1);
    }
  }
}
.ipc-1 {
  position: relative;
  .icp-item {
    padding: 8px !important;
    // max-width: 300px;
  }
  .line-chart-actions-bottom {
    .btn-prev {
      position: absolute;
      left: -44px !important;
      top: -2px;
    }
    .btn-next {
      position: absolute;
      right: -4px !important;
      top: -2px;
    }
  }
}
.ipc-container {
  width: 100%;
  display: flex;
  align-items: center;
  .icp-content {
    display: flex;
  }
  .icp-item {
    display: flex;
    padding: 0 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    // max-width: 300px;
    &:not(:nth-last-child(1)) {
      border-right: 1px solid rgba(42, 42, 48, 0.1);
    }
    .icp-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      gap: 4px;
    }
    .title-icp {
      display: flex;
      padding: 2px 4px;
      align-items: flex-start;
      width: 100%;
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
      max-width: 250px;
    }
    .values {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 11px;
      font-style: normal;
      line-height: 18px;
      letter-spacing: 0.33px;
      color: $monoBlack;
      width: 100%;
      max-width: 250px;
      .name {
        font-weight: 700;
        min-width: fit-content;
        flex-shrink: 1;
        position: relative;
        display: block;
        .question {
          position: absolute;
          left: calc(100% + 4px);
          top: 0;
        }
      }
      .value {
        font-weight: 500;
        max-width: 100%;
        word-wrap: break-word;
        flex-shrink: 0;
      }
    }
    &.blur {
      .values {
        color: $monoMid;
      }
    }
  }

  .actual-present {
    background: $goldMid;
    color: $monoWhite;
  }
  .actual-future {
    background: $monoMid;
    color: $monoWhite;
  }
  .no-achieve-present {
    background: $redMid;
    color: $monoWhite;
  }
  .no-achieve-future {
    background: $redUltraLight;
    color: $redMid;
    border: 0.5px solid $redMid;
  }
}
#style-scroll::-webkit-scrollbar {
  width: 2px;
}
#style-scroll::-webkit-scrollbar-thumb {
  background-color: $bgMid;
  border-radius: 10px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
@media (max-width: $tablet) {
  .line-chart .wj-flexchart {
    width: 105%;
  }
}
@media (max-width: $desktop) {
  .bottom_icp {
    overflow-x: scroll;
  }
  .year-labels,
  .ipc-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.bottom_icp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  margin-left: 0;
  @media (min-width: 1025) {
    margin-top: -28px;
  }
}
.year-labels {
  .year-item {
    // max-width: 300px;
    @media (max-width: 1025) {
      border-top: 1px solid rgba(42, 42, 48, 0.1);
      width: 157px !important;
    }
  }
}
.bottom_icp {
  @media (max-width: 1025) {
     margin-left: 0 !important;
  }
}

.icp-content {
  .icp-item {
    @media (max-width: 1025) {
      width: 157px !important;
      // max-width: 300px;
    }
  }
}
.v-tooltip__content {
  .title-tooltip {
    color: $goldMid;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
  }
  .sub-title-tooltip {
    color: $monoBlack;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.33px;
    display: block;
    margin-top: 4px;
  }
}
</style>
<style lang="scss">
@import '@/components/products/charts/period/line-chart/index.scss';
.reduction-chart {
  .line-chart {
    .wj-flexchart {
      display: flex;
      min-height: calc(459px - 27px - 24px);
      width: 100%;
      left: -6px;
      @media (min-width: $desktop) {
        width: 96.7%;
      }
      .dash-stroke {
        polyline {
          stroke-dasharray: 2;
        }
      }
      .wj-axis-x {
        .wj-label {
          display: none;
          @media (max-width: $desktop) {
            display: inline-block;
          }
        }
      }
      .wj-series-group {
        g {
          ellipse {
            rx: 5;
            ry: 5;
          }
          polyline {
            stroke-width: 1px;
          }
        }
      }
    }
  }
}
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
  z-index: 99 !important;
}
</style>
